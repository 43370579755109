<template>
    <v-progress-linear rounded :value="perc * 100" :color="color" :height="height">
        <template v-slot="{ value }" v-if="height > 10">
            <strong v-if="current_value == 'Infinity'">!</strong>
            <strong v-else-if="format == 'percentage'">{{ formatPercentage(current_value) }} <span v-html="append"></span></strong>
            <strong v-else-if="format == 'currency'">{{ formatCurrency(current_value) }} <span v-html="append"></span></strong>
            <strong v-else>{{ current_value }} <span v-html="append"></span></strong>
        </template>
    </v-progress-linear>
</template>
<script>
export default {
    name: "QProgressStat",
    props: {
        'value': {
            type: Number,
            default: 0
        },
        'animate': {
            type: Boolean,
            default: true
        },
        'format': {
            type: String,
            default: 'default'
        },
        'append': {
            type: String,
            default: ''
        },

        'color': {
            type: String,
            default: 'blue-grey'
        },
        'interval': {
            type: Number,
            default: 50
        },
        'height': {
            type: Number,
            default: 25
        },
        'max': {
            type: Number,
            default: 100
        }
    },
    data: function() { // data internal to component (not available outside)
        return {
            perc: 0,
            repeater: null,
            isAnimating: false,
        }
    },
    mounted: function() {
        if (this.animate) {
            var g = this
            this.perc = 0;
            this.repeater = setInterval(this.animateBar, this.interval)
            this.isAnimating = true;
        } else {
            if (this.value == 0 && this.max == 0) {
                this.perc = 0
                return
            }
            if (this.value > 0 && this.max == 0) {
                this.perc = 1
                return
            }
            if (this.value / this.max > 1) {
                this.perc = 1
                return
            }
            this.perc = this.value / this.max
        }
    },
    computed: {
        current_value: function() {
            if (this.format == 'percentage') {
                if (this.value == 0 && this.max == 0) {
                    return 0
                }
                if (this.value > 0 && this.max == 0) {
                    return 1
                }
                if (this.value / this.max > 1) {
                    return 1
                }
            }
            if (!this.isAnimating) {
                if (this.format == 'percentage') {
                    return this.value / this.max;
                }
                return this.value;
            }
            if (this.format == 'percentage') {
                return this.perc * this.value / this.max;
            }
            return Math.round(this.perc * this.value);
        },
    },
    methods: {
        animateBar: function() {
            if (this.value == 0 && this.max == 0) {
                clearInterval(this.repeater)
                this.perc = 0;
                this.isAnimating = false;
                return
            }
            var target = (this.value / this.max)
            if (this.value > 0 && this.max == 0) {
                target = 1
            }
            this.perc = this.perc + .05;
            if (this.perc >= target) {
                clearInterval(this.repeater)
                this.perc = (this.value / this.max);
                this.isAnimating = false;
            }
        },
    },
    watch: {

    },
}

</script>
<style scoped>
</style>
