<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h1>Component Guide Page</h1>
            </v-col>
            <v-col cols="6">
                <h3>Leaderboard</h3>
                <p>--</p>
                <div>
                    <code>&lt;q-leads-data-table title="Leads" :rows="5"&gt;&lt;/q-leads-data-table&gt;</code>
                </div>
                <q-leaderboard-data-table title="Leaderboard" :rows="5"></q-leaderboard-data-table>
                <v-divider inset />
            </v-col>
            <v-col cols="6">
                <h3>Charts</h3>
                <p>--</p>
                <div>
                    <p>Import<br>
                        <code>import QSampleChart from '@/components/charts/QSampleChart.vue';</code></p>
                    <p>Inside export default<br>
                        <code>... components: { QSampleChart }</code></p>
                    <p>Markup<br>
                        <code>&lt;q-sample-chart&gt;&lt;/q-sample-chart&gt;</code>
                    </p>
                </div>
            </v-col>
            <v-col cols="12">
                <h3>DataTable - WIP</h3>
                <p>--</p>
                <div>
                    <code>&lt;q-leads-data-table title="Leads" :rows="5"&gt;&lt;/q-leads-data-table&gt;</code>
                </div>
                <v-divider inset />
            </v-col>
            <v-col cols="6">
                <h3>Progress Stat</h3>
                <p>'value' and 'max' is the only required attributes. All the rest are optional.</p>
                <div>
                    <p>Import<br>
                        <code>import QProgressStat from '@/components/stats/QProgressStat.vue';</code></p>
                    <p>Inside export default<br>
                        <code>... components: { QProgressStat }</code></p>
                    <p>Markup<br>
                        <code>&lt;q-progress-stat format="percentage" :value="65" :max="100" color="q_blue_3" :animate="true" :interval="50" :height="25" append=" Conversion Rate"&gt;&lt;/q-progress-stat&gt;</code>
                    </p>
                </div>
                <q-progress-stat :value="75" :max="100" color="q_blue_1" :animate="true" :interval="50" :height="25" append=" Total Leads"></q-progress-stat>
                <div>
                    <p>Markup<br>
                        <code>&lt;q-progress-stat format="percentage" :value="65" :max="100" color="q_blue_3" :animate="true" :interval="50" :height="25" append=" Conversion Rate"&gt;&lt;/q-progress-stat&gt;</code>
                    </p>
                </div>
                <q-progress-stat format="percentage" :value="65" :max="100" color="q_blue_3" :animate="true" :interval="50" :height="25" append=" Conversion Rate"></q-progress-stat>
            </v-col>
            <v-col cols="6">
                <h3>Comparison Stat</h3>
                <p>Similar attributes as progress stat. Requires a value, comparison-value and a max.<br>
                    <p>Inside export default<br>
                        <code>import QComparisonStat from '@/components/stats/QComparisonStat.vue';</code></p>
                    <p>Inside export default<br>
                        <code>... components: { QComparisonStat }</code></p>
                    <p>Markup<br>
                        <code>&lt;q-comparison-stat format="currency" :comparison_value="80" :value="150" :max="500" color="q_blue_1" :animate="true&gt;&lt;/q-comparison-stat&gt;</code>
                    </p>
                    <q-comparison-stat format="currency" :comparison_value="80" :value="150" :max="500" color="q_blue_1" :animate="true"></q-comparison-stat>
            </v-col>
            <v-col cols="6">
                <h3>Global Mixins</h3>
                <p>
                    Format Currency<br>
                    <code v-pre>&#123;&#123; formatCurrency(number) &#125;&#125;</code><br>
                    {{ formatCurrency(100000) }}
                </p>
                <p>
                    Format Percentage<br>
                    <code v-pre>&#123;&#123; formatPercentage(.65) &#125;&#125;</code><br>
                    {{ formatPercentage(.65) }}
                </p>
                <p>
                    Format Number - uses the format syntax from <a href="http://numeraljs.com/">http://numeraljs.com/</a><br>
                    <code v-pre>&#123;&#123; formatNumber(123456, '0a') &#125;&#125;</code><br>
                    {{ formatNumber(123456, '0a') }}
                </p>
            </v-col>
            <v-col cols="12">
                <h3>Carrier Stat Card</h3>
                <p>This card uses cols="3"</p>
                <v-container>
                    <v-row>
                        <q-stat-prod-by-carrier />
                        <q-stat-prod-by-carrier />
                        <q-stat-prod-by-carrier />
                        <q-stat-prod-by-carrier />
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QComparisonStat from '@/components/stats/QComparisonStat.vue';
import QProgressStat from '@/components/stats/QProgressStat.vue';
import QLeaderboardDataTable from '@/components/datatables/QLeaderboardDataTable.vue';
import QStatProdByCarrier from '@/components/stats/QStatProdByCarrier.vue';

export default {
    props: [], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
    watch: {

    },
    components: {
        QProgressStat,
        QLeadsDataTable,
        QStatProdByCarrier,
        QComparisonStat,
        QLeaderboardDataTable
    }
}

</script>
<style scoped>
</style>
