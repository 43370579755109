<template>
    <div class="editor">
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs, menu }" class="mb-3">
            <div class="menubar" v-if="show_menu">
                <v-btn x-small @click="commands.bold">
                    <v-icon small :color="isActive.bold() ? 'black' : 'grey'">fas fa-bold</v-icon>
                </v-btn>
                <v-btn x-small class="menubar__button" @click="commands.italic">
                    <v-icon small :color="isActive.italic() ? 'black' : 'grey'">fas fa-italic</v-icon>
                </v-btn>
                <v-btn x-small @click="commands.underline">
                    <v-icon small :color="isActive.underline() ? 'black' : 'grey'">fas fa-underline</v-icon>
                </v-btn>
                <v-btn x-small @click="commands.paragraph()">
                    <v-icon small :color="isActive.paragraph() ? 'black' : 'grey'">fas fa-paragraph</v-icon>
                </v-btn>
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn x-small v-on="on">H</v-btn>
                    </template>
                    <v-card>
                        <v-hover v-for="level in [1,2,3,4,5,6]" :key="level" v-slot:default="{ hover }">
                            <div>
                                <v-btn x-small @click="commands.heading({ level: level, classes:'text-left' })">
                                    <span :class="isActive.heading({ level: 1 }) ? 'black--text' : 'grey--text'"><strong>H{{level}}</strong></span>
                                </v-btn>
                                <div style="display:inline-block" v-if="hover">
                                    <v-btn x-small @click="commands.heading({ level: level, classes:'text-left' })">
                                        <v-icon small :color="isActive.heading({ level: level, classes : 'text-left'}) ? 'black' : 'grey'">fas fa-align-left</v-icon>
                                    </v-btn>
                                    <v-btn x-small @click="commands.heading({ level: level, classes:'text-center' })">
                                        <v-icon small :color="isActive.heading({ level: level, classes : 'text-center'}) ? 'black' : 'grey'">fas fa-align-center</v-icon>
                                    </v-btn>
                                    <v-btn x-small @click="commands.heading({ level: level, classes:'text-right' })">
                                        <v-icon small :color="isActive.heading({ level: level, classes : 'text-right'}) ? 'black' : 'grey'">fas fa-align-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-hover>
                    </v-card>
                </v-menu>
                <v-btn x-small @click="commands.bullet_list">
                    <v-icon small :color="isActive.bullet_list() ? 'black' : 'grey'">fas fa-list-ul</v-icon>
                </v-btn>
                <v-btn x-small @click="commands.ordered_list">
                    <v-icon small :color="isActive.ordered_list() ? 'black' : 'grey'">fas fa-list-ol</v-icon>
                </v-btn>
                <v-btn x-small @click="commands.horizontal_rule">
                    _
                </v-btn>
            </div>
        </editor-menu-bar>
        <editor-content class="editor__content" :editor="editor" />
    </div>
</template>
<script>
import Icon from './Icon.vue'
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Strike,
    Underline,
    History,
    Image,
    Paragraph
} from 'tiptap-extensions'
//import Align from './tip_tap_align.js';
import Div from './tip_tap_div.js';
import Heading from './tip_tap_heading.js';
//import Paragraph2 from './tip_tap_paragraph.js';

export default {
    components: {
        EditorContent,
        EditorMenuBar,
        Icon,
        EditorMenuBubble,
    },
    props: ['value'],
    data() {
        return {
            show_menu: false,
            editor: new Editor({
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({ levels: [1, 2, 3, 4, 5, 6] }),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                    new Div(),
                ],
                content: ``,
                onBlur: () => {
                    //this.$emit('input', this.editor.getHTML());
                },
                onFocus: () => {
                    this.show_menu = true;
                    this.$emit('hasfocus');
                },
                onDrop(view, event, slice, moved) {
                    // return true to stop the drop event
                    // this will just prevent drop from external sources
                    return !moved;
                },
            }),

        }
    },
    mounted: function() {
        this.editor.setContent(this.value);
        this.show_menu = true
    },
    beforeDestroy() {
        this.editor.destroy()
    },
    methods: {
        formatLabel: function(key) {
            var str = key.replace("_", " ");
            str = str.replace("-", " ");
            return this.titleCase(str);
        },
        getHtml: function() {
            return this.editor.getHTML();
        },
        setHtml: function(v) {
            this.editor.setContent(v);
        }
    },
    watch: {
        'value': function(newV) {
            this.editor.setContent(newV);
        }
    }
}

</script>
<style>
.editor {
    width: 100%;
}

.editor__content input:focus {
    outline: none;
}

[contenteditable]:focus {
    outline: 0px solid transparent;
}

.editor__content {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

}

</style>
