<template>
    <div class="q-comparison-stat">
        <h4 v-if="title">{{ title }}</h4>
        <!--<div class="stat">
            <span class="value" v-if="format == 'currency'">{{ formatCurrency(value) }}</span>
            <span class="value" v-else-if="format == 'percentage'">{{ formatPercentage(value) }}</span>
            <span class="value" v-else>{{ value }}</span>
            <span :class="compare_value_class" v-if="comparison_value">({{ formatPercentage(percentage_change) }})</span>
        </div>
        -->
        <q-progress-stat :value="value" :format="format" :max="max" :color="color" :animate="animate" :interval="interval" :height="height" :append="appended_text"></q-progress-stat>
        <!--<q-progress-stat :value="comparison_value" :max="max" color="#dddddd" :animate="animate" :interval="interval" :height="height"></q-progress-stat>-->
    </div>
</template>
<script>
import QProgressStat from '@/components/stats/QProgressStat.vue';

export default {
    name: "QComparisonStat",
    props: {
        'title': {
            type: String,
            default: ''
        },
        'value': {
            type: Number,
            default: 0
        },
        'comparison_value': {
            type: Number,
            default: null
        },
        'animate': {
            type: Boolean,
            default: true
        },
        'format': {
            type: String,
            default: 'default' // also pass currency. 
        },
        'color': {
            type: String,
            default: 'blue-grey'
        },
        'interval': {
            type: Number,
            default: 50
        },
        'height': {
            type: Number,
            default: 30
        },
        'max': {
            type: Number,
            default: 100
        },
        'append': {
            type: String,
            default: ''
        },
    },
    data: function() { // data internal to component (not available outside)
        return {
            perc: 0,
            repeater: null,
            isAnimating: false,
        }
    },
    mounted: function() {
        if (this.animate) {
            var g = this
            this.perc = 0;
            this.repeater = setInterval(this.animateBar, this.interval)
            this.isAnimating = true;
        }
    },
    computed: {
        percentage_change: function() {
            return (this.value - this.comparison_value) / this.comparison_value;
        },
        current_value: function() {
            if (!this.isAnimating) {
                if (this.format == 'percentage') {
                    return Math.round(this.value / this.max * 100);
                }
                return this.value;
            }
            if (this.format == 'percentage') {
                return Math.round(this.perc * this.value / this.max * 100);
            }
            return Math.round(this.perc * this.value);
        },
        compare_value_class: function() {
            var r_class = 'compare-value';
            if (this.percentage_change < 0) {
                return r_class + " negative"
            }
            return r_class + " positive"
        },
        appended_text: function() {
            var appended_text = "";
            if (this.append) {
                appended_text = this.append;
            }
            if (this.comparison_value) {
                if (this.comparison_value == 0) {
                    return ""
                }
                return "<span class='compare value " + this.compare_value_class + "'>(" + this.formatPercentage(this.percentage_change) + ")</span><span class='appended-text'>" + appended_text + "</span>";
            }
            return appended_text;
        }
    },
    methods: {
        animateBar: function() {
            this.perc = this.perc + .05;
            if (this.perc >= (this.value / this.max)) {
                clearInterval(this.repeater)
                this.perc = (this.value / this.max);
                this.isAnimating = false;
            }
        },
    },
    watch: {

    },
    components: {
        QProgressStat
    }
}

</script>
<style scoped>
</style>
