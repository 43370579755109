var render, staticRenderFns
import script from "./QAgentUplineCarrierDataTable.vue?vue&type=script&lang=js&"
export * from "./QAgentUplineCarrierDataTable.vue?vue&type=script&lang=js&"
import style0 from "./QAgentUplineCarrierDataTable.vue?vue&type=style&index=0&id=61ee9c27&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ee9c27",
  null
  
)

export default component.exports