<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-popup-submenu></q-popup-submenu>
        <!-- Charts-->
        <v-row justify="center" align="stretch" class="mx-5" id="EverPlans">
            <v-col cols="12">
                <h2>{{popup_notice.title}}</h2>
            </v-col>
            <v-col cols="3" class="text-center">
                <v-card>
                    <v-card-text>
                        <h1>{{popup_notice.audience_count}}</h1>
                        <div class="subtitle mt-3">Total</div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="3" class="text-center">
                <v-card>
                    <v-card-text>
                        <h1>{{popup_notice.acknowledged_count}}</h1>
                        <div class="subtitle mt-3"># Acknowledged</div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="3" class="text-center">
                <v-card>
                    <v-card-text>
                        <h1>{{ formatPercentage(popup_notice.acknowledged_count / popup_notice.audience_count) }}</h1>
                        <div class="subtitle mt-3">Acknowledgement Rate</div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <q-popup-log-data-table></q-popup-log-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QPopupSubmenu from '@/components/navigation/Submenus/QPopupSubmenu.vue'
import QPopupLogDataTable from '@/components/datatables/QPopupLogDataTable.vue'
import PopupNotice from '@/store/Models/PopupNotice'
import PopupLog from '@/store/Models/PopupLog'
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    name: "PopupNoticeIndex",
    data() {
        return {

        };
    },
    mounted: function() {
        if (this.popup_log == null) {
            QuilityAPI.getPopupNotice(this.$route.params.id);
        }
    },
    computed: {
        popup_notice: function() {
            if (PopupNotice.find(this.$route.params.id)) {
                return PopupNotice.find(this.$route.params.id);
            }
            return {}
        }
    },
    methods: {

    },
    components: {
        QPopupSubmenu,
        QPopupLogDataTable
    }
};

</script>
