<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-configure-affiliate-widget></q-configure-affiliate-widget>
    </v-container>
</template>
<script>
import QConfigureAffiliateWidget from '@/components/affiliate_widget_editor/QConfigureAffiliateWidget.vue'
export default {
    name: "ConfigureAffiliateWidget",
    props: [], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
    watch: {

    },
    components: {
        QConfigureAffiliateWidget,
    }
}
</script>
<style scoped>
</style>