<template>
    <div>
        <v-progress-linear indeterminate v-if="saving"></v-progress-linear>
        <v-form v-model="valid" ref="form" :disabled="saving">
            <v-row wrap dense>
                <v-col cols="12" v-if="error">
                    <v-alert type="error">
                        {{ error }}
                    </v-alert>
                </v-col>
                <v-col cols="12" v-if="warning">
                    <v-alert type="warning">
                        {{ warning }}
                    </v-alert>
                </v-col>
                <v-col cols="12">
                    <q-division-select label="Division*" v-model="Staff.Division" :with-all="true"></q-division-select>
                </v-col>
                <v-col cols="12">
                    <v-select :disabled="saving" :rules="[form_rules.required]" label="Role" v-model="Staff.Role" :items="role_types"></v-select>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Name" v-model="Staff.Name" :rules="[form_rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Email" v-model="Staff.Email" :rules="[form_rules.required, form_rules.email]"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-btn block color="primary" @click="saveStaff">
                        Add
                        <v-progress-circular :disabled="saving" right size="30" v-if="saving" indeterminate></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDivisionSelect from '@/components/utils/QDivisionSelect.vue'
export default {
    props: [],
    data() {
        return {
            Staff: {},
            saving: false,
            warning: null,
            error: null,
            valid: false,
        }
    },
    mounted: function() {
        this.reset()
    },
    computed: {
        'role_types': function() {
            return [
                { text: "Staff", value: "Staff" },
                { text: "Executive", value: "Exec" },
            ]
        },
    },
    methods: {
        saveStaff() {
            var g = this
            if (!this.$refs.form.validate()) {
                this.warning = "Please correct invalid fields."
                setTimeout(function() { g.warning = null }, 6000)
                this.saving = false;
                return false;
            }
            this.saving = true;
            var p = QuilityAPI.addStaff(this.Staff)
            //now process the promise. 
            p.then(function(resp) {
                    if (resp.success !== true) {
                        g.error = resp.msg
                        setTimeout(function() { g.error = null }, 6000)
                    } else {
                        g.$emit('saved', resp)
                        g.reset();
                    }
                    g.saving = false;
                })
                .catch(function(err) {
                    g.error = err.msg
                    setTimeout(function() { g.error = null }, 6000)
                    g.saving = false;
                });
        },
        reset() {
            this.warning = null
            this.saving = false
            this.$set(this, "Staff", {});
        }
    },
    watch: {

    },
    components: {
        QDivisionSelect
    }
}

</script>
