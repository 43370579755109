<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-popup-submenu></q-popup-submenu>
        <!-- Charts-->
        <v-row id="popuplist" justify="space-between" align="stretch" class="mx-5">
            <v-col cols="12">
                <q-popup-data-table title="Sent Messages"></q-popup-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QPopupSubmenu from '@/components/navigation/Submenus/QPopupSubmenu.vue'
import QPopupDataTable from '@/components/datatables/QPopupDataTable.vue'
export default {
    name: "PopupNoticeIndex",
    data() {
        return {

        };
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
    components: {
        QPopupSubmenu,
        QPopupDataTable
    }
};

</script>
