<template>
    <div>
        <v-row>
            <v-col cols="12">
                <h3>Laravel Logs</h3>
                <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            </v-col>
            <v-col cols="6" sm="3">
                <v-select v-model="log_type" label="Log" :items="avaialable_log_types"></v-select>
            </v-col>
            <v-col cols="6" sm="3">
                <v-select v-model="log_file" label="Available Files" :items="avaialable_logs"></v-select>
            </v-col>
        </v-row>
        <v-card v-if="log_file">
            <v-card-title>
                <h3 class="text-left with-short-rule">{{ log_file }}</h3>
            </v-card-title>
            <v-card-text>
                <div id="log_container" class="scroll elevation-0 pa-4" style="height: calc(60vh - 175px); text-align:left; border:#ccc 1px solid; ">
                    <pre>{{log}}</pre>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Page from '@/CMS/views/index.vue'
import ReleaseMeta from '@/ReleaseMeta.json'
import { debounce } from 'vue-debounce'

export default {
    props: [],
    data() {
        return {
            loading: false,
            log_type: 'agent',
            log_file: null,
            log: null,
            avaialable_log_types: [
                'agent',
                'opt',
                'laravel',
                'laravel_queries',
                'leads'
            ],
            avaialable_logs: []
        }
    },
    mounted: function() {
        this.loadAvailableLogs()
    },
    methods: {
        loadAvailableLogs: debounce(function(opt) {
            var g = this
            this.loading = true
            QuilityAPI.getAvailableLogs(this.log_type, this.log_file).then(function(json) {
                g.loading = false
                g.$set(g, 'avaialable_logs', json.data);
                if (json.log) {
                    g.$set(g, 'log', json.log);
                }
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
            });
            this.firstPull = false;
        }, 200),
    },
    watch: {
        'log_type': function(newV) {
            this.log_file = null;
            this.$nextTick(this.loadAvailableLogs)
        },
        'log_file': function(newV) {
            this.$nextTick(this.loadAvailableLogs)
        },
        'log': function() {
            var g = this
            this.$nextTick(function() {
                var container = g.$el.querySelector("#log_container");
                container.scrollTop = container.scrollHeight;
            });

        }
    },
    components: {
        Page,
    }
}

</script>
