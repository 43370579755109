<template>
    <div class="about pa-5">
        <h1>Route List</h1>
        <v-text-field v-model="search" :disabled="isDemoMode" label="search"></v-text-field>
        <v-data-table :headers="headers" :search="search" :items="routes" :items-per-page="50" class="elevation-1">
            <template v-slot:item.path="{ item }">
                <router-link :to="item.path">{{item.path}}</router-link>
            </template>
        </v-data-table>
    </div>
</template>
<script>
export default {
    name: "RouteList",
    data() {
        return {
            search: null,
            str: null,
            routes: []
        };
    },
    mounted: function() {
        this.resetRouteList();
    },
    computed: {
        headers() {
            return [{
                text: "Name",
                value: "name"
            }, {
                text: "Path",
                value: "path"
            }, {
                text: "File",
                value: "file"
            }, {
                text: "Permissions",
                value: "permission",
                filterable: false
            }]
        }
    },
    methods: {
        resetRouteList: function() {
            var r = []
            for (var i = 0; i < this.$router.options.routes.length; i++) {
                r[i] = {
                    "name": this.$router.options.routes[i].name,
                    "path": this.$router.options.routes[i].path,
                    "permission": this.$router.options.routes[i].meta ? this.$router.options.routes[i].meta.permission : null,
                    "file": null
                }
                this.setComponent(i);
            }
            this.$set(this, 'routes', r);
        },
        setComponent: function(i) {
            var g = this
            var component = this.$router.options.routes[i].component;
            if (typeof component == 'function') {
                var d = this.$router.options.routes[i].component().then(function(d) {
                    g.routes[i].file = d.default.__file;
                });
            } else {
                d = component
                if (typeof d != 'undefined' && typeof d.default != 'undefined') {
                    g.routes[i].file = d.default.__file;
                }
            }
        }
    },
    components: {

    }
};

</script>
