<!--
The main wrapper for the add agent form.
//-->
<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agency-building-submenu></q-agency-building-submenu>
        <!-- Charts-->
        <div class="mx-5">
            <v-card>
                <v-card-title>
                    <h3 class="pb-1 mb-1">Add New Agent</h3>
                    <v-spacer />
                    <v-btn depressed @click="resetForm" class="ml-4" small>Reset</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <q-form-new-agent ref="agentForm"></q-form-new-agent>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </v-container>
</template>
<script>
import QFormNewAgent from '@/components/agent_admin/QFormNewAgent.vue'
import QAgencyBuildingSubmenu from '@/components/navigation/Submenus/QAgencyBuildingSubmenu.vue';


export default {
    data: function() {
        return {

        }
    },
    methods: {
        resetForm: function() {
            this.$refs.agentForm.reset();
        }
    },
    computed: {},
    components: {
        QFormNewAgent,
        QAgencyBuildingSubmenu
    }
}

</script>
