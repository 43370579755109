<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <!-- Charts-->
        <q-product-library-admin-submenu></q-product-library-admin-submenu>
        <v-row justify="space-between" align="stretch" class="mx-5">
            <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>
            <v-col cols="12">
                <h3>Edit <span v-if="carrier">{{carrier.CarrierName}}</span>
                    <v-btn class="ml-4" @click="saveCarrier" color="primary">Save</v-btn>
                    <v-btn v-if="carrier_id !== 'new'" class="ml-4" @click="viewCarrierProducts">View Products</v-btn>
                </h3>
                <v-row wrap dense v-if="carrier">
                    <v-col cols="12" sm="6">
                        <v-text-field label="Carrier Name" v-model="carrier.CarrierName" :rules="[form_rules.required]"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="3">
                        <v-text-field label="Short Name" v-model="carrier.CarrierShortName" :rules="[form_rules.required]"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="3">
                        <v-checkbox label="Core" v-model="carrier.CoreCarrierInd" :false-value="0" :true-value="1"></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field label="Carrier Name in SureLC" v-model="carrier.CarrierNameSureLC"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-textarea :value="alternateNames(carrier)" auto-grow label="Alternate Names" rows="1" :disabled="true"></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-autocomplete label="Parent Carrier" item-value="value" item-text="text" v-model="carrier.ParentCarrierID" :items="parentCarriers"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select label="Solution" v-model="carrier.Solution" :rules="[form_rules.required]" :items="['Life', 'Annuity']">
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field messages="https://" label="Carrier URL" v-model="carrier.CarrierURL"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field messages="https://" label="Agent Login URL" v-model="carrier.AgentLoginURL"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field messages="https://" label="eApp URL" v-model="carrier.CarrierEAppURL"></v-text-field>
                    </v-col>
					<v-col cols="12" sm="6">
                        <v-text-field messages="https://" label="Client Login URL" v-model="carrier.ClientLoginURL"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field label="Known Writing Number Formats" v-model="carrier.KnownWritingNumberFormats"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="carrier.B2BCarrierEAppURL" label="B2B eApp URL" messages="https://" />
                    </v-col>
                </v-row>
                <v-row wrap dense v-if="carrier">
                    <v-col cols="6">
                        <v-col cols="12">
                            <h4>Divisions</h4>
                        </v-col>
                        <div v-if="all_divisions.length > 0">
                            <v-row v-for="division in all_divisions" :key="division.ID" dense>
                                <v-col cols="4" class="pt-4 pl-4">{{division.Division}}</v-col>
                                <v-col cols="4">
                                    <v-checkbox label="Active" true-value="1" false-value="0" v-model="division.ActiveInd" @change="toggleDivisionSetting($event, division, 'ActiveInd')" />
                                </v-col>
                                <v-col cols="4">
                                    <v-checkbox label="Hidden" true-value="1" false-value="0" v-model="division.HiddenInd" @change="toggleDivisionSetting($event, division, 'HiddenInd')" />
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="12">
                                <h4>Contact</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Staff Contact Name" v-model="carrier.StaffContactName"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Staff Phone" v-model="carrier.StaffPhone" :rules="[form_rules.phone]"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Staff Phone Ext" v-model="carrier.StaffPhoneExt"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Agent Phone" v-model="carrier.AgentPhone" :rules="[form_rules.phone]"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Staff Email" v-model="carrier.StaffEmail" :rules="[form_rules.email]"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Staff Fax Number" v-model="carrier.StaffFaxNumber" :rules="[form_rules.phone]"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Hours of Operation" v-model="carrier.HoursOfOperation" messages="Format: Mon-Fri | 8am-5pm CT"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-row>
                        <v-col cols="6">
                            <v-col cols="12">
                                <h4>Codes</h4>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="NAIC Code" v-model="carrier.NAICCode"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="NAIC Group Code" v-model="carrier.NAICGroupCode"></v-text-field>
                            </v-col>
                        </v-col>
                        <v-col cols="6">
                            <v-col cols="12">
                                <h4>Content</h4>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field placeholder="https://" label="CMS Url" v-model="carrier.CarrierCMSURL"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <!-- <v-text-field label="Carrier Logo URL" v-model="carrier.CarrierLogoURL"></v-text-field> -->
                                <image-selector :aspect-ratio="16/9" v-model="carrier.CarrierLogoURL" label="Carrier Logo" :show-image="true"></image-selector>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-row>
            </v-col>
        </v-row>
        <v-dialog v-model="show_media" width="75%">
            <media-library v-on:usefile="useMediaFile"></media-library>
        </v-dialog>
        <v-dialog v-model="show_documents" width="75%">
            <document-library :is-popup="true" :just-link="true" v-on:usefile="useDocumentFile"></document-library>
        </v-dialog>
    </v-container>
</template>
<script>
import QCarrierDataTable from '@/components/datatables/QCarrierDataTable.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QProductLibraryAdminSubmenu from '@/components/navigation/Submenus/QProductLibraryAdminSubmenu.vue'
import Page from '@/CMS/views/index.vue'
import ImageSelector from '@/CMS/editor/components/ImageSelector.vue'
import MediaLibrary from '@/CMS/editor/mediaLibrary.vue'
import { EventBus } from '@/event-bus'
export default {
    name: "CarrierDetail",
    data() {
        return {
            carrier: null,
            loading: false,
            parentCarriers: null,
            show_media: false, //shows the media library
            show_documents: false, // shows the document library
            all_divisions: []
        };
    },
    mounted: function() {
        if (this.carrier === null)
            this.loadCarrier();
        if (this.parentCarriers === null)
            this.loadParentCarriers();

        var g = this
        EventBus.$on('medialibrary', function(callback) {
            g.show_media = true;
            g.mediaCallback = callback;
        });
        EventBus.$on('documentlibrary', function(callback) {
            g.show_documents = true;
            g.documentCallback = callback;
        });
    },
    computed: {
        "carrier_id": function() {
            return this.$route.params.carrier_id
        },

    },
    methods: {
        loadCarrier: function() {
            this.carrier = null;
            if (this.carrier_id == null || this.loading === true) {
                return;
            }

            if (this.carrier_id == 'new') {
                this.carrier = {
                    ID: 'new',
                    AgentLoginURL: "",
                    CarrierCMSURL: null,
                    CarrierName: "",
                    CarrierNameSureLC: "",
                    CarrierShortName: "AMAM",
                    CarrierURL: "",
                    StaffContactName: null,
                    StaffEmail: null,
                    NAICCode: null,
                    NAICGroupCode: null,
                    ParentCarrierID: null,
                    AgentPhone: null,
                    StaffFaxNumber: null,
                    Solution: "",
                    CarrierLogoURL: "https://hq-image-cdn.azureedge.net/servers/public/cms_images/16x9-placeholder_wvC2Ck.jpg",
                    alternateNames: [],
					B2BCarrierEAppURL: '',
                }
                return
            }

            var g = this
            this.loading = true
            QuilityAPI.getCarrier(this.carrier_id).then(function(json) {
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                json.data.ParentCarrierID = parseInt(json.data.ParentCarrierID)
                if (json.data.CarrierLogoURL === null)
                    json.data.CarrierLogoURL = "https://hq-image-cdn.azureedge.net/servers/public/cms_images/16x9-placeholder_wvC2Ck.jpg"
                g.$set(g, 'carrier', json.data);
                g.loadAllDivisions()
                g.loading = false
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
        },
        saveCarrier: function() {
            var g = this
            g.loading = true

            if (this.carrier_id === 'new') {
                QuilityAPI.createCarrier(this.carrier).then(function(json) {
                    g.loading = false
                    if (typeof json.response != 'undefined' && json.response.clientError) {
                        g.showError(json.response.body.message);
                        return
                    }
                    if (json.error) {
                        g.showError(json.msg)
                        return
                    }
                    g.$set(g, 'carrier', json.data);
                    g.showSuccess("Carrier Created!");
                    localStorage.setItem("refreshCarriers", true);
                    g.carrier.divisions = g.all_divisions
                    QuilityAPI.updateDivisionCarrier(g.carrier)

                });
                return
            }
            QuilityAPI.updateCarrier(this.carrier).then(function(json) {
                g.loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                if (json.error) {
                    g.showError(json.msg)
                    return
                }
                g.$set(g, 'carrier', json.data);
                g.showSuccess("Saved!");
                g.carrier.divisions = g.all_divisions
                QuilityAPI.updateDivisionCarrier(g.carrier)
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });

        },
        loadParentCarriers: function() {
            var g = this
            this.loading = true

            QuilityAPI.getPossibleCarrierNames().then(function(json) {
                g.loading = false
                g.parentCarriers = json.map(carrier => {
                    let possibleCarrierNames = carrier.PossibleCarrierNames.split(";")
                    let carrierText = carrier.CarrierName + " - " + possibleCarrierNames.join(", ")
                    return { text: carrierText, value: carrier.CarrierID }
                })
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
        },
        loadAllDivisions: function() {
            var g = this

            QuilityAPI.getDivisions().then(function(json) {
                g.all_divisions = json.data

                //set initial values for divisions (ActiveInd, HiddenInd)
                g.all_divisions.map(div => {
                    // see if this division exists in the carrier divisions
                    let this_div = g.carrier.divisions.find(carrier_division => {
                        return carrier_division.DivisionID == div.ID
                    })

                    //set the initial values
                    if (this_div !== undefined) {
                        div.ActiveInd = this_div.ActiveInd
                        div.HiddenInd = this_div.HiddenInd
                        div.DivisionCarrierProductID = this_div.ID
                    }
                })

            })

        },
        viewCarrierProducts: function() {
            this.$router.push({ name: 'ProductList', params: { carrier_id: this.carrier_id } })
        },
        useMediaFile: function(file) {
            this.mediaCallback(file);
            this.show_media = false;
        },
        useDocumentFile: function(file) {
            this.documentCallback(file.url, file.title);
            this.show_documents = false;
        },
        toggleDivisionSetting: function(newValue, division, field) {
            console.log(newValue, division, field)
        },
        alternateNames: function(carrier) {
            const names = carrier.alternateNames.map(name => name.AlternateCarrierName)
            return names.join(', ')
        }
    },
    components: {
        QCarrierDataTable,
        QProductLibraryAdminSubmenu,
        Page,
        ImageSelector,
        MediaLibrary
    },
    watch: {
        'carrier_id': function(newV, oldV) {
            if (newV === null || oldV === 'new') {
                return
            }

            this.carrier = null;
            this.$nextTick(this.loadCarrier)
        }
    }
};
</script>