<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <!-- Charts-->
        <v-row justify="space-between" align="stretch" class="mx-5">
            <v-col cols="12">
                <h2>Staff HQ Accounts</h2>
            </v-col>
            <v-col cols="12">
                <v-btn @click="showAddStaff">Add Staff Login</v-btn>
            </v-col>
            <v-col cols="12">
                <q-staff-data-table></q-staff-data-table>
            </v-col>
        </v-row>
        <v-dialog v-model="show_staff" width="500">
            <v-card>
                <v-card-title>Add Staff Login</v-card-title>
                <v-card-text>
                    <q-form-add-staff v-on:saved="show_staff = false"></q-form-add-staff>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import QFormAddStaff from '@/components/agent_admin/QFormAddStaff.vue'
import QStaffDataTable from '@/components/datatables/QStaffDataTable.vue'
export default {
    name: "ManageStaff",
    data() {
        return {
            show_staff: false
        };
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {
        showAddStaff: function() {
            this.show_staff = true
        }
    },
    components: {
        QFormAddStaff,
        QStaffDataTable
    }
};

</script>
