<!--
The agent form for creating agents. The edit agent form is different, as we won't be importing data from onboarding. Basically edits the dbo.Agent table, creates Opt logins and pull data from the onboarding portal.
//-->
<template>
    <div style="max-width:1400px; margin:0 auto;">
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <v-stepper id="stepper" v-else-if="baseAgent" v-model="step">
            <v-stepper-header>
                <v-stepper-step :step="1">
                    <span>Agent Details
                        <!--
                        Not working correctly... please fix later...
                            <v-icon v-if="!valid2" right color="red">fas fa-exclamation-triangle</v-icon></span>
                        //-->
                    </span>
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :step="2">
                    Documents & Info
                </v-stepper-step>
                <v-divider></v-divider>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-form lazy-validation v-model="valid2" ref="form1" :disabled="saving">
                        <h4>Agent Details</h4>
                        <!-- corporate info-->
                        <v-row class="d-flex" wrap dense>
                            <v-col cols="12" v-if="error">
                                <v-alert type="error">
                                    {{ error }}
                                </v-alert>
                            </v-col>
                            <v-col cols="12" v-if="warning || npnwarning">
                                <v-alert type="warning" v-if="warning">
                                    {{ warning }}
                                </v-alert>
                                <v-alert type="warning" v-if="npnwarning">
                                    Full functionality of HQ will not be available without a valid NPN number.
                                </v-alert>
                            </v-col>
                            <v-col cols="12" class="mt-6">
                                <h5>Corporate Information</h5>
                            </v-col>
                            <v-col cols="12" wrap class="d-inline-flex ml-5 wrap">
                                <v-card elevation="0" class="ma-4 flex-grow-1">
                                    <q-division-select label="Division*" v-model="baseAgent.Division" :rules="[rules.required]"></q-division-select>
                                </v-card>
                                <v-card elevation="0" class="ma-4 flex-grow-1">
                                    <v-select label="Leadership Level*" :items="leadership_levels" item-value="id" item-text="LevelName" v-model="baseAgent.LeadershipLevelID" :rules="[rules.required]" prepend-icon="fas fa-crown"></v-select>
                                </v-card>
                            </v-col>
                            <v-col cols="12" class="d-inline-flex ml-5">
                                <v-card elevation="0" class="ma-4 flex-grow-1">
                                    <q-agent-lookup :filters="{'Status' : 'Active,Available,Lapsed,Stalled,Terminated'}" label="Upline Agent" v-model="baseAgent.UplineAgentCode" :rules="[rules.required]" :division="baseAgent.Division" placeholder="Start typing to Search for Upline"></q-agent-lookup>
                                </v-card>
                            </v-col>
                            <v-col cols="12" class="d-inline-flex ml-5">
                                <v-card elevation="0" class="ma-4 ">
                                    <v-select label="Contract Level*" :items="contract_levels" item-value="id" item-text="LevelName" v-model="baseAgent.ContractLevelID" :rules="[rules.required]" prepend-icon="fas fa-file-contract"></v-select>
                                </v-card>
                                <v-card elevation="0" class="ma-4 ">
                                    <q-date-picker v-model="baseAgent.ContractStartDt" label="Contract Start Date"></q-date-picker>
                                </v-card>
                                <v-card elevation="0" class="ma-4 ">
                                    <v-select label="Status*" :items="status_levels" item-value="id" item-text="Status" v-model="baseAgent.Status" :rules="[rules.required]" prepend-icon="fas fa-thumbs-up"></v-select>
                                </v-card>
                                <v-card elevation="0" class="ma-4 " v-if="baseAgent.Division == 'Wholesale'">
                                    <v-checkbox label="Fully Licensed?" v-model="baseAgent.IsFullyLicensed"></v-checkbox>
                                </v-card>
                                <v-card elevation="0" class="ma-4 " v-if="baseAgent.Division == 'Wholesale'">
                                    <v-text-field label="NPN #" v-model="baseAgent.NPN"></v-text-field>
                                </v-card>
                            </v-col>
                            <!--numbers-->
                            <v-col cols="12" class="d-inline-flex ml-5 wrap">
                                <v-card elevation="0" class="ma-4 flex-grow-1">
                                    <v-text-field label="Ext UID" v-model="baseAgent.ExtUID"></v-text-field>
                                </v-card>
                            </v-col>
                            <v-col cols="12" class="d-inline-flex ml-5 wrap">
                                <v-card elevation="0" class="ma-4 flex-grow-1">
                                    <v-text-field label="Lead Agent ID" v-model="baseAgent.LeadAgentID" :rules="[rules.number]"></v-text-field>
                                </v-card>
                                <v-card elevation="0" class="ma-4 flex-grow-1">
                                    <v-text-field label="Agency Integrator ID" v-model="baseAgent.AgencyIntegratorID" :rules="[rules.number]"></v-text-field>
                                </v-card>
                                <v-card elevation="0" class="ma-4 flex-grow-1">
                                    <v-checkbox label="PCS Agent" v-model="baseAgent.PCSAgentInd"></v-checkbox>
                                    <q-date-picker :disabled="!baseAgent.PCSAgentInd" v-model="baseAgent.PCSAgentStartDate" label="PCS Start Date"></q-date-picker>
                                    <q-date-picker :disabled="!baseAgent.PCSAgentInd" v-model="baseAgent.PCSAgentEndDate" label="PCS End Date"></q-date-picker>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-divider class="my-6"></v-divider>
                        <!--personal info-->
                        <v-row class="" wrap dense>
                            <v-col cols="12" class="mt-6">
                                <h5>Personal Information</h5>
                            </v-col>
                            <v-col cols="12" class="d-inline-flex ml-5">
                                <v-card elevation="0" class="ma-4 flex-grow-1">
                                    <v-text-field label="First Name*" v-model="baseAgent.FirstName" :rules="[rules.required, rules.not_upper_case, rules.no_special_characters]"></v-text-field>
                                </v-card>
                                <v-card elevation="0" class="ma-4 flex-grow-1">
                                    <v-text-field label="Middle Name" v-model="baseAgent.MiddleName" :rules="[rules.no_special_characters]"></v-text-field>
                                </v-card>
                                <v-card elevation="0" class="ma-4 flex-grow-1">
                                    <v-text-field label="Last Name*" v-model="baseAgent.LastName" :rules="[rules.required, rules.not_upper_case, rules.no_special_characters]"></v-text-field>
                                </v-card>
                                <v-card elevation="0" class="ma-4 flex-grow-1">
                                    <v-select label="Suffix" v-model="baseAgent.Suffix" :items="['', 'Sr', 'Jr', 'II', 'III', 'IV']"></v-select>
                                </v-card>
                            </v-col>
                            <v-col cols="12" class="d-inline-flex ml-5">
                                <v-card elevation="0" class="ma-4 d-flex flex-grow-1">
                                    <div class="flex-grow-1">
                                        <v-text-field label="Preferred Name" v-model="baseAgent.PreferredName" :rules="[rules.not_upper_case,rules.valid_name_characters]"></v-text-field>
                                    </div>
                                    <div style="width:40px;">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="#999999" dark v-bind="attrs" v-on="on">
                                                    fas fa-question-circle
                                                </v-icon>
                                            </template>
                                            <span>
                                                <p style="max-width:200px">Fill out Preferred Name with a full name or complete agency name. This field will primarily be displayed on leaderboards and be used as entered, not combined with a last name or nick name. </p>
                                            </span>
                                        </v-tooltip>
                                    </div>
                                </v-card>
                                <v-card elevation="0" class="ma-4 d-flex flex-grow-1">
                                    <div class="flex-grow-1">
                                        <v-text-field label="Nickname" v-model="baseAgent.NickName" :rules="[rules.not_upper_case]"></v-text-field>
                                    </div>
                                    <div style="width:40px;">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="#999999" dark v-bind="attrs" v-on="on">
                                                    fas fa-question-circle
                                                </v-icon>
                                            </template>
                                            <span>
                                                <p style="max-width:200px">The agent Nick Name will replace their first name in the Agent Directory. For example if an agent goes by a different first name, or uses their middle name as their first name, then enter that name here.</p>
                                            </span>
                                        </v-tooltip>
                                    </div>
                                </v-card>
                                <v-card elevation="0" class="ma-4 d-flex flex-grow-1">
                                    <div class="flex-grow-1">
                                        <v-checkbox label="Hide Middle Name" v-model="baseAgent.HideMiddlename"></v-checkbox>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" class="d-inline-flex ml-5">
                                <v-card elevation="0" class="ma-4 ">
                                    <q-date-picker v-model="baseAgent.BirthDt" label="Birth Date"></q-date-picker>
                                </v-card>
                                <v-card elevation="0" class="ma-4 ">
                                    <v-text-field label="SSN #" v-model="baseAgent.SSN" :rules="[rules.required]"></v-text-field>
                                </v-card>
                            </v-col>
                            <v-divider />
                        </v-row>
                        <v-divider class="my-6"></v-divider>
                        <!--contact info-->
                        <v-row>
                            <v-col cols="12" class="mt-6">
                                <h5>Contact Information</h5>
                            </v-col>
                            <v-col cols="12" class="d-inline-flex">
                                <v-card elevation="0" class="ma-5 flex-grow-1">
                                    <v-text-field label="Email*" v-model="baseAgent.Email" :rules="[rules.required, rules.email]"></v-text-field>
                                </v-card>
                                <v-card elevation="0" class="ma-5">
                                    <v-text-field label="Business Email" v-model="baseAgent.BusinessEmail" :rules="[rules.nullable_email]"></v-text-field>
                                </v-card>
                                <v-card elevation="0" class="ma-5 ">
                                    <v-text-field label="Phone*" v-model="baseAgent.Phone" :rules="[rules.required, rules.phone]"></v-text-field>
                                </v-card>
                                <v-card elevation="0" class="ma-5 ">
                                    <v-text-field label="Business Phone" v-model="baseAgent.BusinessPhone" :rules="[rules.phone]"></v-text-field>
                                </v-card>
                                <v-card elevation="0" class="ma-5 ">
                                    <v-text-field label="Cell Phone" v-model="baseAgent.CellPhone" :rules="[rules.phone]"></v-text-field>
                                </v-card>
                            </v-col>
                            <v-col cols="12" class="d-inline-flex">
                                <v-card elevation="0" class="ma-4 flex-grow-1">
                                    <v-text-field label="Street Address" v-model="baseAgent.Street1" :rules="[rules.required, rules.not_upper_case]"></v-text-field>
                                </v-card>
                            </v-col>
                            <v-col cols="12" class="d-inline-flex">
                                <v-card elevation="0" class="ma-4 ">
                                    <v-text-field label="City" v-model="baseAgent.City" :rules="[rules.required, rules.not_upper_case]"></v-text-field>
                                </v-card>
                                <v-card elevation="0" class="ma-4 ">
                                    <v-text-field label="County" v-model="baseAgent.County" :rules="[rules.required, rules.not_upper_case]"></v-text-field>
                                </v-card>
                                <v-card elevation="0" class="ma-4 ">
                                    <v-select label="State*" :items="states" v-model="baseAgent.State" :rules="[rules.required]"></v-select>
                                </v-card>
                                <v-card elevation="0" class="ma-4 ">
                                    <v-text-field label="Zip*" v-model="baseAgent.Zip" :rules="[rules.required, rules.zip]"></v-text-field>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-divider class="mb-6"></v-divider>
                        <!--DBA-->
                        <v-row>
                            <v-col cols="12">
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox label="Doing Business as a Business Entity?" v-model="isBusiness"></v-checkbox>
                            </v-col>
                            <v-row v-if="baseAgent.isBusiness" class="ml-5">
                                <h5>Business Info</h5>
                                <v-col cols="12" class="d-inline-flex">
                                    <v-card elevation="0" class="ma-4 flex-grow-1">
                                        <v-text-field label="Business Name" v-model="baseAgent.DoingBusinessAsName" :rules="[rules.required, rules.not_upper_case]"></v-text-field>
                                    </v-card>
                                    <v-card elevation="0" class="ma-4 flex-grow-1">
                                        <v-text-field label="Business Type" v-model="baseAgent.business.Type" :rules="[rules.required, rules.not_upper_case]"></v-text-field>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="d-inline-flex">
                                    <v-card elevation="0" class="ma-4 ">
                                        <v-text-field label="Business Phone" v-model="baseAgent.business.Phone" :rules="[rules.required, rules.phone]"></v-text-field>
                                    </v-card>
                                    <v-card elevation="0" class="ma-4 ">
                                        <v-text-field label="Business Fax" v-model="baseAgent.business.Fax"></v-text-field>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="d-inline-flex">
                                    <v-card elevation="0" class="ma-4 ">
                                        <v-text-field label="Business NPN #" v-model="baseAgent.DoingBusinessAsNPN" :rules="[rules.required]"></v-text-field>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="d-flex">
                                    <v-card elevation="0" class="ma-4 flex-grow-1" style="max-width:400px">
                                        <v-textarea outlined label="Info" v-model="baseAgent.business.Info"></v-textarea>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-col cols="12">
                                <v-divider class="mb-6"></v-divider>
                            </v-col>

                            <v-col cols="12">
                                <v-divider class="mb-6"></v-divider>
                                <v-progress-linear right size="20" v-if="saving" indeterminate></v-progress-linear>
                            </v-col>
                            <v-col cols="12" class="d-flex align-end">
                                <v-btn text v-if="baseAgent.AgentID == null" @click="step = 1" :disabled="saving">
                                    <v-icon left>fas fa-arrow-alt-circle-left</v-icon>
                                    Back
                                </v-btn>
                                <v-spacer></v-spacer>
                                <div class="mx-8" style="max-width:400px;">
                                    <p><strong>Clicking Save & Next will perform a number of steps.</strong></p>
                                    <ol>
                                        <li>Create the agent and their upline</li>
                                        <li>Create the Agent in Opt!</li>
                                        <li>Create a sign for the agent in HQ.</li>
                                    </ol>
                                    <p class="pt-3">In the next step you can transfer documents from the Onboarding Portal to this Agent's Document repository on HQ.</p>
                                    <v-divider class="mb-6"></v-divider>
                                    <v-alert type="warning" v-if="npnwarning">
                                        Full functionality of HQ will not be available without a valid NPN number.
                                    </v-alert>
                                    <v-btn color="primary" depressed @click="saveAgent(true)" :disabled="saving">
                                        Save & Next
                                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <v-form lazy-validation v-model="valid3" ref="form2" :disabled="saving">
                        <h3>Documents & Info</h3>
                        <v-progress-linear right size="20" v-if="transferringDocs" indeterminate></v-progress-linear>
                        <v-row wrap dense v-if="onboardingAgent && onboardingAgent.entries[onboardingAgent_entry_id]">
                            <v-col cols="6" sm="4">
                                <v-btn block color="primary" @click="transferAllDocs">Transfer All Documents
                                    <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                                </v-btn>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr v-for="(doc, key) in onboarding_documents" :key="key">
                                                <td v-if="doc.file_url"><a :href="doc.file_url" target="_tab">{{doc.name}}</a></td>
                                                <td v-if="doc.file_url" class="text-right">
                                                    <v-btn @click="transferDoc(doc)">Transfer to Docs
                                                        <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <v-col cols="1">
                                <v-divider vertical></v-divider>
                            </v-col>
                            <v-col cols="5" sm="4">
                                <h4>EFT Info</h4>
                                <strong>{{onboarding_info.eft.bank_name}}</strong> ({{onboarding_info.eft.account_type}})<br>
                                Account Holder: <strong>{{onboarding_info.eft.account_holder_name}}</strong><br>
                                Routing Number: <strong>{{onboarding_info.eft.routing_number}}</strong><br>
                                Account Number: <strong>{{onboarding_info.eft.account_number}}</strong>
                            </v-col>
                        </v-row>
                        <v-row wrap dense v-else>
                            No Documents.
                        </v-row>
                        <v-row wrap dense>
                            <v-col cols="12" v-if="baseAgent.agent && baseAgent.agent.AgentCode">
                                <v-row wrap>
                                    <v-spacer />
                                    <v-col cols="6" sm="7">
                                        <h4 v-if="baseAgent.IsFullyLicensed">
                                            New Agent's Opt Info:<br>
                                            OptID : <strong>{{baseAgent.OptID}}</strong><br>
                                            Password : <strong>{{baseAgent.OptPassword}}</strong>
                                        </h4>
                                        <span v-if="baseAgent.IsFullyLicensed">Copy this information down if you need it to send to the agent!!!<br></span>
                                        <v-btn large color="primary" :to="{ name: 'AgentDetail', params: { agentcode: baseAgent.AgentCode ? baseAgent.AgentCode : baseAgent.agent.AgentCode} }" class="mr-6 mt-4">Finish & View Agent
                                            <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-stepper-content>
                </v-row>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>
<script>
import QDatePicker from '@/components/utils/QDatePicker.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Level from '@/store/Models/AgentManagement/Level'
import QAgentLookup from '@/components/utils/QAgentLookup.vue'
import moment from 'moment'
import OptLeadOptions from '@/store/OptLeadOptions'
import StateOptions from '@/store/StateOptions'
import StateAbbreviations from '@/store/StateAbbreviations'
import rules from '@/components/utils/ValidationRules'
import QDivisionSelect from '@/components/utils/QDivisionSelect.vue'
export default {
    props: [],
    data() {
        return {
            baseAgent: {
                isBusiness: false,
                business: {},
                Division: null,
                IsFullyLicensed: false
            }, // holds the base agent data for saving to dbo.Agent
            isBusiness: false,
            saving: false, //for progress bars
            warning: null,
            error: null,
            valid1: true, //these hold whether the various forms are valid or not.
            valid2: true,
            valid3: true,
            valid4: true,
            states: StateOptions, //for the state drop down list
            state_abbreviations: StateAbbreviations,
            rules: rules, //validation rules imported.
            agent_loading: false, //progress indicator
            step: 1,
            lookupEmail: 'malikbb2@icloud.com',
            onboardingAgent: null, // the agent values loaded from the onboarding portal
            onboardingAgent_entry_id: null,
            transferringDocs: false,
        }
    },
    mounted: function() {
        //gets the data for the drop downs.
        this.getLevels();
    },
    computed: {
        //filters the data from levels for each type of level - Leadership and Contract
        'contract_levels': function() {
            return Level.query().where("LevelType", 'Contract').get()
        },
        'leadership_levels': function() {
            return Level.query().where("LevelType", 'Leadership').get()
        },
        //The status drop down options.
        'status_levels': function() {
            return [
                'Active',
                'Available',
                'Lapsed',
                'Stalled',
                'Terminated'
            ]
        },
        //we don't show the form until all the drop data is loaded.
        'loading': function() {
            return this.$store.state.entities.level.loading || this.agent_loading;
        },
        'loaded': function() {
            return this.$store.state.entities.level.loaded;
        },
        //pull from static json file imported above.
        'lead_options': function() {
            return OptLeadOptions
        },
        //Sets up and array of possible files that may be present in the onboarding portal, and the keys associated with pulling them.
        'onboarding_documents': function() {
            if (this.onboardingAgent_entry_id) {
                // an array of documents we may want along with the keys from the onboarding portal.
                var files = [{
                        "name": "Business Articles",
                        "key": "56.3.please-upload-a-copy-of-your-articles-of-incorporation"
                    },
                    {
                        "name": "Payment Plan",
                        "key": "177.3.please-upload-a-copy-proof-of-payment-plan"
                    },
                    {
                        "name": "Enrollment Proof",
                        "key": "35.3.please-upload-proof-of-having-enrolled-in-a-pre-licensing-course"
                    },
                    {
                        "name": "Passed Exam Proof",
                        "key": "35.3.please-upload-proof-of-having-successfully-passed-your-state-exam"
                    },
                    {
                        "name": "Contract",
                        "key": ""
                    },
                    {
                        "name": "Course Certificate",
                        "key": "213.3.please-upload-a-copy-of-your-course-completion-certificate"
                    },
                    {
                        "name": "Voided Check",
                        "key": "213.3.upload-a-copy-of-a-voided-check-or-bank-authorization-letter"
                    },
                    {
                        "name": "E&O Certificate",
                        "key": "213.3.upload-a-copy-of-your-eo-certificate"
                    },
                ]
                //check if document exist and adds to the array for return
                var r = []
                for (var i = 0; i < files.length; i++) {
                    var file = this.onboardingAgent.entries[this.onboardingAgent_entry_id].entry_meta[files[i].key]
                    if (file == null) {
                        continue;
                    }
                    //fix the file name from wierd onbboarding portal format.
                    file = file.replace('["', '').replace('"]', '').replace(/\\/g, '');
                    var file_name = file.substring(file.lastIndexOf("/") + 1);
                    r.push({
                        "name": files[i].name,
                        "file_url": file,
                        "file_name": file_name
                    })
                }
                //couple of hard coded ones - these aren't in the normal meta returned by the onboarding portal.
                r.push({
                    "name": "Contract",
                    "file_url": this.onboardingAgent.entries[this.onboardingAgent_entry_id]['contract'],
                    "file_name": "Agent_Contract.pdf"
                })
                r.push({
                    "name": "Signature",
                    "file_url": "https://symmetryonboarding.com/wp-content/uploads/gravity_forms/signatures/" + this.onboardingAgent.entries[this.onboardingAgent_entry_id].entry_meta['233.1.signature'],
                    "file_name": "Signature.png"
                })
                r.push({
                    "name": "Legal Explanations",
                    "file_url": this.onboardingAgent.entries[this.onboardingAgent_entry_id]['legal_explanations'],
                    "file_name": "LegalExplanations.pdf"
                })
                r.push({
                    "name": "Surelc Agreement",
                    "file_url": this.onboardingAgent.entries[this.onboardingAgent_entry_id]['surelc_agreement'],
                    "file_name": "SureLCAgreement.pdf"
                })
                return r;
            }
            return {};
        },
        //possibly not needed anymore but we display it for now.
        'onboarding_info': function() {
            if (this.onboardingAgent_entry_id) {
                return {
                    "eft": {
                        "account_holder_name": this.onboardingAgent.entries[this.onboardingAgent_entry_id].entry_meta['213.3.account-holder-name'],
                        "routing_number": this.onboardingAgent.entries[this.onboardingAgent_entry_id].entry_meta['213.3.routing-number'],
                        "account_number": this.onboardingAgent.entries[this.onboardingAgent_entry_id].entry_meta['213.3.account-number'],
                        "account_type": this.onboardingAgent.entries[this.onboardingAgent_entry_id].entry_meta['213.3.account-type'],
                        "bank_name": this.onboardingAgent.entries[this.onboardingAgent_entry_id].entry_meta['213.3.financial-institution-name'],
                    },
                    "printed_name": this.onboardingAgent.entries[this.onboardingAgent_entry_id].entry_meta['237.1.printed-name'],
                }
            }
            return {};
        },
        'npnwarning': function() {
            return !this.baseAgent.NPN
        }
    },
    methods: {
        //gets the Agent Levels entries for populating the drop downs - use compute functions to segment the various types.
        getLevels() {
            var g = this;
            QuilityAPI.getAgentManagement_Levels().then(function() {
                g.reset();
            })
        },
        //gets the base agent if we have a AgentCode passed to us via the props
        //since we split this off from the edit form, we just return the blank agent object.
        getBaseAgent() {
            this.isBusiness = false
            this.baseAgent = this.$set(this, 'baseAgent', {
                ID: null,
                UplineAgentCode: null,
                LeadTypeDefault: null,
                LeadTypesAvailable: [],
                ContractStartDt: moment().format('YYYY-MM-DD'),
                ContractLevelID: 775346, // 80
                LeadershipLevelID: 622183, // sales rep
                Division : 'Field',
                isBusiness: false,
                business: {},
                Status: 'Active',
                IsFullyLicensed: false,
            })
            return
        },
        //slides the form to the top
        goToTop: function() {
            this.$vuetify.goTo('#stepper', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },
        //goes to the next step in the stepper.
        nextStep: function() {
            this.goToTop();
            this.step = this.step + 1
        },
        //if we need to translate a Level Name to an ID, use this passing the level name.
        getLevelID: function(level) {
            return Level.query().where("LevelName", level).first()
        },
        //look up an agent from the onboarding portal.
        //this processes the step one of the form
        lookupAgent() {
            var g = this
            //check if the step one form is valid.
            if (!this.$refs['form' + this.step].validate()) {
                this.goToTop();
                this.warning = "Please correct invalid fields."
                setTimeout(function() { g.warning = null }, 6000)
                this.saving = false;
                return false;
            }
            this.saving = true;
            //now process the promise.
            QuilityAPI.lookupOnboardingAgent(this.lookupEmail).then(function(resp) {
                    if (resp.success === true) {
                        g.onboardingAgent = resp.data.user
                    } else {
                        g.error = resp.reason
                        g.goToTop();
                        setTimeout(function() { g.error = null }, 6000)
                    }
                    g.saving = false;
                })
                .catch(function(err) {
                    g.error = err.message
                    setTimeout(function() { g.error = null }, 6000)
                    g.saving = false;
                });
        },
        //for the button click when an user wants to use a particular agent from the results.
        useOnboardingAgent(entry_id) {
            //translate all  the fields from onboarding to the fields needed for the agent form.
            this.onboardingAgent_entry_id = entry_id;
            if (this.onboardingAgent.entries[entry_id].upline_agent != null) {
                this.baseAgent.UplineAgentCode = this.onboardingAgent.entries[entry_id].upline_agent.AgentCode
            }
            if (typeof this.onboardingAgent.entries[entry_id].division == 'undefined') {
                this.baseAgent.Division = 'Field';
            } else {
                this.baseAgent.Division = this.onboardingAgent.entries[entry_id].division;
            }
            if (typeof this.onboardingAgent.user_meta.contract_level != "undefined") {
                var level = this.onboardingAgent.user_meta.contract_level.replace('%', '');
            } else {
                level = null
            }

            this.baseAgent.IsFullyLicensed = true;
            this.baseAgent.ContractLevelID = this.getLevelID(level) ? this.getLevelID(level).id : null;
            this.baseAgent.FirstName = this.onboardingAgent.user_meta.first_name
            this.baseAgent.LastName = this.onboardingAgent.user_meta.last_name
            //this.baseAgent.PreferredName = this.onboardingAgent.user_meta.first_name + " " + this.onboardingAgent.user_meta.last_name
            this.baseAgent.Email = this.onboardingAgent.user_email
            this.baseAgent.Phone = this.onboardingAgent.entries[entry_id].entry_meta['18.3.cell-phone'].replace(")", "-").replace("(", "").replace(" ", "");
            this.baseAgent.Street1 = this.onboardingAgent.entries[entry_id].entry_meta['191.1.confirm-your-current-address.street-address']
            this.baseAgent.County = this.onboardingAgent.entries[entry_id].entry_meta['25.6.county-of-residence']
            this.baseAgent.City = this.onboardingAgent.entries[entry_id].entry_meta['191.3.confirm-your-current-address.city']
            this.baseAgent.State = this.getStateAbbreviation(this.onboardingAgent.entries[entry_id].entry_meta['191.4.confirm-your-current-address.state-province'])
            this.baseAgent.Zip = this.onboardingAgent.entries[entry_id].entry_meta['191.5.confirm-your-current-address.zip-postal-code']

            this.baseAgent.SSN = this.onboardingAgent.entries[entry_id].entry_meta['16.8.social-security-number']
            this.baseAgent.NPN = this.onboardingAgent.entries[entry_id].entry_meta['29.6.npn-number']
            this.baseAgent.BirthDt = this.onboardingAgent.entries[entry_id].entry_meta['18.1.date-of-birth.year'] != null ? this.onboardingAgent.entries[entry_id].entry_meta['18.1.date-of-birth.year'] + "-" + this.onboardingAgent.entries[entry_id].entry_meta['18.1.date-of-birth.month'] + "-" + this.onboardingAgent.entries[entry_id].entry_meta['18.1.date-of-birth.day'] : null;

            //add doing business as information.
            if (this.onboardingAgent.entries[entry_id].entry_meta['35.3.doing-business-as'] != 'Individual') {
                this.baseAgent.isBusiness = true;
                this.isBusiness = true;
                this.baseAgent.DoingBusinessAsName = this.onboardingAgent.entries[entry_id].entry_meta['45.3.business-name'];
                this.baseAgent.DoingBusinessAsNPN = this.onboardingAgent.entries[entry_id].entry_meta['35.3.business-insurance-license-number'];
                var phone = this.onboardingAgent.entries[entry_id].entry_meta['45.3.business-phone'] ? this.onboardingAgent.entries[entry_id].entry_meta['45.3.business-phone'] : '';
                this.baseAgent.business = {
                    'Type': this.onboardingAgent.entries[entry_id].entry_meta['45.3.company-type'],
                    'Phone': phone.replace(")", "-").replace("(", "").replace(" ", ""),
                    'Fax': this.onboardingAgent.entries[entry_id].entry_meta['45.3.business-fax'] != null ? this.onboardingAgent.entries[entry_id].entry_meta['45.3.business-fax'].replace(")", "-").replace("(", "").replace(" ", "") : null,
                    'Info': this.onboardingAgent.entries[entry_id].entry_meta['35.3.business-entity-information'],
                }
            }
            this.baseAgent.Status = 'Active'
            //move on to the next step, all the fields should be populated that have available data.
            this.step = 2
        },
        //saves the agent... also creates the Opt account if there isn't one already.
        saveAgent(nextStep) {
            var g = this
            //check the form for validation.
            if (!this.$refs['form' + this.step].validate()) {
                this.goToTop();
                this.warning = "Please correct invalid fields."
                setTimeout(function() { g.warning = null }, 6000)
                this.saving = false;
                return false;
            }
            this.saving = true;
            //now process the promise.
            QuilityAPI.createBaseAgent(this.baseAgent).then(function(resp) {
                    if (resp.error === true) {
                        g.error = resp.message ? resp.message : resp.msg + " " + resp.errors;
                        g.goToTop();
                        g.showError(g.error);
                        setTimeout(function() { g.error = null }, 6000)
                    } else {
                        g.$set(g, 'baseAgent', resp.data)
                        g.$emit('saved', resp.data)
                        if (nextStep) {
                            g.nextStep()
                        }
                    }
                    g.saving = false;
                })
                .catch(function(err) {
                    g.error = err.message ? err.message : err.msg + " " + err.errors;
                    g.showError(g.error);
                    setTimeout(function() { g.error = null }, 6000)
                    g.saving = false;
                });
        },
        //transfers all documents to the newly created agent's folder.
        //creates and array and then the array is passed to the transferDoc function.
        transferAllDocs() {
            var docs = [];
            for (var i = 0; i < this.onboarding_documents.length; i++) {
                docs.push(this.onboarding_documents[i])
            }
            this.transferDoc(docs);
        },
        //will except a single url or an array of urls.
        transferDoc(urls) {
            //we want an array - if we pass an object then put it in an array.
            if (!Array.isArray(urls)) {
                urls = [urls]
            }
            var g = this
            this.transferringDocs = true
            //forwards all the urls to the backend and the backend processes and downloads the docs and stores in the azure blob storage.
            QuilityAPI.transferOnboardingDocument(urls, this.baseAgent.agent.AgentCode).then(function(json) {
                if (typeof json.data != 'undefined' && typeof json.meta.msg != 'undefined' && json.meta.msg != '') {
                    g.showError(json.data.length + " Documents uploaded. " + json.meta.msg)
                } else {
                    g.showSuccess("Successfully Transferred!")
                }
                g.transferringDocs = false
            }).catch(function(json) {
                g.showError("There was a problem! Please transfer manually.")
                g.transferringDocs = false
            })
        },
        reset() {
            this.warning = null
            this.saving = false
            this.getBaseAgent();
            this.step = 1;
            this.onboardingAgent = null;
            this.onboardingAgent_entry_id = null;
            this.lookupEmail = null
        },
        //translates a full name state into an abbreviation.
        getStateAbbreviation(state) {
            if (this.states.indexOf(state) > -1) {
                return state
            }
            if (typeof this.state_abbreviations[state] != 'undefined') {
                return this.state_abbreviations[state];
            }
            return null
        },
    },
    watch: {
        'isBusiness': function(newV) {
            this.baseAgent.isBusiness = newV;
        },
        'baseAgent.Division': function(newV) {
            this.baseAgent.UplineAgentCode = null
        },
    },
    components: {
        QAgentLookup,
        QDatePicker,
        QDivisionSelect
    }
}
</script>