<template>
    <QDynamicSubmenu
        :base-url="baseUrl"
        :default-title="title"
        :default-buttons="buttons"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QPopupSubmenu",
    data() {
        return {
            title: "Messages",
            baseUrl: '/admin/sent-messages/',
        }
    },
    computed: {
        buttons() {
            const buttons = [{
                label: "Messages Inbox",
                to: { name: 'Notices' },
                icon: "fas fa-arrow-alt-circle-down",
            }]

            if (this.hasRole(['SuperAdmin', 'AgencyOwner', "Exec", 'Staff']) && this.notRole(['SalesRep', 'UnlicensedAgent'])) {
                buttons.push({
                    label: "Sent Messages",
                    to: { name: 'PopupNoticeAdmin' },
                    icon: "fas fa-arrow-alt-circle-down",
                })
            }

            return buttons
        },
    },
    components: {
        QDynamicSubmenu,
    },
}

</script>

<style></style>
