import { render, staticRenderFns } from "./QStatProdByCarrier.vue?vue&type=template&id=ec8eb408&scoped=true&"
import script from "./QStatProdByCarrier.vue?vue&type=script&lang=js&"
export * from "./QStatProdByCarrier.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec8eb408",
  null
  
)

export default component.exports