<template>
    <div class="about">
        <h1>Bulk Uploads</h1>
        <QTabs dark v-model="tab">
            <v-tab>Upload</v-tab>
            <v-tab>Logs</v-tab>
            <v-tab>File Formats</v-tab>
        </QTabs>
        <v-tabs-items v-model="tab">
            <v-tab-item class="pa-6">
                <v-row justify="center" v-if="!jobStarted">
                    <v-col cols="12">
                        <div>
                            <div v-if="!hasUpload && !isDemoMode">
                                <h4 class=" font-weight-bold" style="position:relative">
                                    Upload
                                </h4>
                                <vue-dropzone ref="myVueDropzone" v-on:vdropzone-success="uploadComplete" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                            </div>
                            <div v-if="hasUpload">
                                <h4 class=" font-weight-bold" style="position:relative">
                                    Job #{{ job.ID }} ~ {{uploadType.text}}
                                </h4>
                                <v-row>
                                    <v-col cols="2"></v-col>
                                    <v-col cols="8">
                                        <v-select v-model="uploadType" required label="Select Upload Type" :items="allowed_bulk_uploads" return-object :rules="[form_rules.required]"></v-select>
                                    </v-col>
                                    <v-col cols="2"></v-col>
                                    <v-col cols="12" v-if="uploadType.headers" class="elevation-5">
                                        <p if="uploadType.text == 'Agent Update'"><strong><em>Be sure to include the AgentCode. Note: Agent's email or NPN numbers cannot be updated by this tool. </em></strong></p>
                                        Sample Data:
                                        <v-data-table dense :headers="uploadType.headers" :items="sampleData"></v-data-table>
                                    </v-col>
                                    <v-col cols="12" class="text-center">
                                        <v-btn
                                            v-if="uploadType.headers"
                                            :disabled="uploading"
                                            x-large
                                            color="primary"
                                            class="mr-5"
                                            @click="processUpload"
                                        >Import Data</v-btn>
                                        <v-btn @click="reset">Reset</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center" v-if="jobStarted">
                    <v-col cols="12" class="text-center">
                        <img src="/assets/images/GoodJob.gif"><br>
                        <p>Your job is in the queue! We will email you when it's done processing. Check the logs tab to view any errors.</p>
                        <v-btn @click="reset">Reset</v-btn>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item class="pa-6">
                <q-bulk-job-data-table title="Bulk Upload Job Log"></q-bulk-job-data-table>
            </v-tab-item>
            <v-tab-item class="pa-6">
                <h4 class="font-weight-bold">
                    CSV Templates
                </h4>
                <p>Most files will require you to enter the AgentCode for the agent data you want to update. You can find the agent code by looking them up in the agent directory and clicking the <v-icon small>fas fa-chevron-down</v-icon> icon to open the expansion panel and see the Agent Code.
                    <p>The files below are Excel sheets, please save as CSV files before uploading.
                        <ul>
                            <li><a href="/storage/bulk_import_formats/AgentLevel.xlsx">AgentLevels.xlsx</a></li>
                            <li><a href="/storage/bulk_import_formats/StaffImport.xlsx">StaffImport.xlsx</a></li>
                            <li><a href="/storage/bulk_import_formats/AgentUpdate.xls">AgentUpdate.xls</a></li>
                        </ul>
                    </p>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { getInstance } from "@/auth/index";
import QuilityAPI from '@/store/API/QuilityAPI.js'
import BulkImportHeaders from '@/store/bulk_import_header_formats.json';
import QBulkJobDataTable from '@/components/datatables/QBulkJobDataTable.vue'
import QTabs from '../../components/navigation/QTabs.vue'

export default {
    data() {
        return {
            tab: 0,
            hasUpload: false,
            jobStarted: false,
            uploadType: {
                permission: null,
                text: null,
                model: null,
                f: function() {},
                headers: null
            },
            bulk_uploads: [{
                permission: "manage:AgentLevel",
                text: "Agent Levels",
                model: 'AgentLevel',
                f: function() {},
                headers: BulkImportHeaders.AgentLevel
            }, {
                permission: "manage:Staff",
                text: "Staff Import",
                model: 'StaffLogins',
                f: function() {},
                headers: BulkImportHeaders.StaffLogins
            }, {
                permission: "manage:Agent",
                text: "Agent Import",
                model: 'BaseAgent',
                f: function() {},
                headers: BulkImportHeaders.BaseAgent
            }, {
                permission: "manage:Agent",
                text: "Agent Update",
                model: 'BaseAgent',
                f: function() {},
                headers: BulkImportHeaders.BaseAgentUpdate
            }, {
                permission: "manage:Agent",
                text: "Agent Bonus - PCS",
                model: 'AgentBonusPCS',
                f: function() {},
                headers: BulkImportHeaders.AgentBonusTypePCS
            }, {
                permission: "manage:Agent",
                text: "Agent Bonus - QRS & FIF",
                model: 'AgentBonusQRS',
                f: function() {},
                headers: BulkImportHeaders.AgentBonusTypeQRS
            }, {
                permission: "manage:Agent",
                text: "Agent Bonus - Capital",
                model: 'AgentBonusCapital',
                f: function() {},
                headers: BulkImportHeaders.AgentBonusTypeCapital
            }, {
                permission: "manage:Agent",
                text: "Agent Bonus - Sherpa",
                model: 'AgentBonusSherpa',
                f: function() {},
                headers: BulkImportHeaders.AgentBonusTypeSherpa
            }, {
                permission: "manage:Agent",
                text: "Agent Bonus - BirdDog",
                model: 'AgentBonusBirdDog',
                f: function() {},
                headers: BulkImportHeaders.AgentBonusTypeBirdDog
            }, {
                permission: "manage:Agent",
                text: "Agent Bonus - Producer",
                model: 'AgentBonusProducer',
                f: function() {},
                headers: BulkImportHeaders.AgentBonusTypeProducer
            }, {
                permission: "manage:Agent",
                text: "Agent Bonus - Slingshot",
                model: 'AgentBonusSlingshot',
                f: function() {},
                headers: BulkImportHeaders.AgentBonusTypeSlingshot
            }, {
                permission: "manage:Agent",
                text: "Agent Bonus - Equity",
                model: 'AgentBonusEquity',
                f: function() {},
                headers: BulkImportHeaders.AgentBonusTypeEquity
            }],
            dropzoneOptions: {
                url: '/api/private/bulkjob/upload',
                headers: {
                    'Authorization': null,
                    'Accept': 'application/json',
                },
                addRemoveLinks: true,
                acceptedFiles: ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
                'paramName': 'bulkdata',
                maxFiles: 1
            },
            token: null,
            job: null,
            sampleData: [],
            uploading: false,
        }
    },
    mounted: function() {
        var g = this
        this.config().then(function(token) {
            g.token = token
            g.dropzoneOptions.headers.Authorization = `Bearer ${token}`;
        })
    },
    computed: {
        allowed_bulk_uploads: function() {
            var r = [];
            for (var i = 0; i < this.bulk_uploads.length; i++) {
                if (this.hasPermission(this.bulk_uploads[i].permission)) {
                    r.push(this.bulk_uploads[i]);
                }
            }
            return r;
        },
    },
    methods: {
        'reset': function() {
            this.hasUpload = false;
            this.jobStarted = false;
            this.sampleData = [];
            this.job = null;
            this.uploadType = {
                permission: null,
                text: null,
                model: null,
                f: function() {},
                headers: null
            }
        },
        'uploadComplete': function(file, response) {
            this.hasUpload = true;
            this.job = response.data
            this.getSampleData();
        },
        config: function() {
            return new Promise((resolve, reject) => {
                getInstance().getTokenSilently().then(function(token) {
                    resolve(token);
                });
            })
        },
        'getSampleData': function() {
            var g = this;
            QuilityAPI.getSampleBulkJobData(this.job.ID).then(function(resp) {
                g.$set(g, 'sampleData', resp.data);
            }).catch(function(err) {
                console.log(err);
                g.showError(err.reason);
            });
        },
        processUpload() {
            this.uploading = true
            QuilityAPI.processUpload(this.job.ID, this.uploadType).then((resp) => {
                console.log(resp);
                if (resp.error === true) {
                    this.showError(resp.msg);
                } else {
                    this.jobStarted = true
                }
            }).catch((err) => {
                console.log(err);
                this.showError(err.reason);
            }).finally(() => {
                this.uploading = false
            });
        }
    },
    components: {
        vueDropzone: vue2Dropzone,
        QBulkJobDataTable,
        QTabs,
    }
}
</script>