<template>
    <v-card>
        <v-card-title>
            <h5>{{ title }}</h5>
        </v-card-title>
        <v-card-text class="scroll-overflow">
            <a target="_tab" :href="url">{{ url }}</a>
            <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
            <pre>apiMessage : {{ apiMessage }}</pre>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="callApi()">Test Api</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import axios from "axios";

export default {
    name: "QApiTest",
    props: ['url', 'title'],
    data() {
        return {
            apiMessage: "",
            loading: false,
        };
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {
        callApi() {
            var g = this;
            this.loading = true;
            g.apiMessage = null;
            // Get the access token from the auth wrapper
            this.$auth.getTokenSilently().then(
                function(token) {
                    axios.get(g.url, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    }).then(
                        function(data) {
                            g.loading = false
                            g.apiMessage = JSON.stringify(data.data, null, 2);
                        }
                    ).catch(function(e) {
                        g.apiMessage = JSON.stringify(e.response.data, null, 2);
                        g.loading = false;
                    });;
                }
            ).catch(function(e) {
                g.apiMessage = "TOKEN ERROR :: " + JSON.stringify(e, null, 2);
                g.loading = false;
            });
        }
    }
};

</script>
<style scoped>
.scroll-overflow {
    max-height: 400px;
    overflow: scroll;
}

</style>
