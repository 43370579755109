<template>
    <QDynamicSubmenu
        :default-title="title"
        :default-buttons="buttons"
        :submenu="[]"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QAdminAuditSubmenu",
    data() {
        return {
            title: "Audit & Logs",
        }
    },
    computed: {
        buttons() {
            return [{
                label: "Agent Audit",
                to: "/admin/audit/agent-audit",
                icon: "fas fa-arrow-alt-circle-right",
            }, {
                label: "Logs",
                to: "/admin/audit/logs",
                icon: "fas fa-arrow-alt-circle-right",
            }, {
                label: "Activity log",
                to: "/admin/audit/agent_logins",
                icon: "fas fa-arrow-alt-circle-right",
            }]
        },
    },
    components: {
        QDynamicSubmenu,
    },
}

</script>

<style></style>
