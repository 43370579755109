<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <p class="q-display-3 pl-7 pb-0 pt-0 mt-0 mb-0 white--text">{{ the_title }}</p>
                        <p class="pt-0 mt-0 pl-5">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filters
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="9" sm="4" class="pr-7 mb-0">
                        <v-btn v-on:click="refreshData()">Refresh</v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <v-container v-if="showFilters">
                <slot name="filters"></slot>
            </v-container>
            <v-data-table dense v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="items" item-key="$id" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items">
                <template v-slot:item.Submitted="{ item }">
                    <span class="nowrap">{{ formatCurrency(item.Submitted) }}</span>
                </template>
                <template v-slot:item.NetPlaced="{ item }">
                    <span class="nowrap">{{ formatCurrency(item.NetPlaced) }}</span>
                </template>
                <template v-slot:item.NetPlacedPercentage="{ item }">
                    <span class="nowrap">{{ formatPercentage(item.NetPlacedPercentage/100) }}</span>
                </template>
                <template v-slot:item.PPL="{ item }">
                    <span class="nowrap">{{ formatCurrency(item.PPL) }}</span>
                </template>
                <template v-slot:item.PI="{ item }">
                    <span class="nowrap">{{ formatCurrency(item.PI) }}</span>
                </template>
                <template v-slot:item.CloseRatio="{ item }">
                    <span class="nowrap">{{ formatPercentage(item.CloseRatio/100) }}</span>
                </template>
                <template v-slot:item.CarrierNetPlacedPercentage="{ item }">
                    <span class="nowrap">{{ formatPercentage(item.CarrierNetPlacedPercentage/100) }}</span>
                </template>
                <template v-slot:item.CarrierSubmittedPercentage="{ item }">
                    <span class="nowrap">{{ formatPercentage(item.CarrierSubmittedPercentage/100) }}</span>
                </template>
                <template v-slot:item.NPP="{ item }">
                    <span class="nowrap">{{ formatPercentage(item.NPP/100) }}</span>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "../QDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QStoredProcDataTableBase",
    mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            items: [],
            items_length: 0
        }
    },
    computed: {
        the_headers: function() {
            return [];
        },
        total_items: function() {
            return this.items_length;
        }
    },
    methods: {
        loadData: debounce(function(opt) {

        }, 200),
    },
    watch: {

    },
    components: {}
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
