<template>
    <v-col cols="12" sm="6" md="3">
        <v-card class="mx-auto ma-1 pa-2" color="" elevation="1">
            <v-card-title class="headline mb-1">
                {{carrier}}
            </v-card-title>
            <v-card-subtitle>
                Graph showing product breakdown
            </v-card-subtitle>
            <v-card-text class="">
                <v-img :src="require('@/assets/images/sample_piechart.png')" max-width="125px" class="center-image" />
            </v-card-text>
            <v-card-actions class="pt-5 pb-5">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-btn :href="href" class="mx-2 pt-4 pb-4" dark x-small color="q_blue_1" v-on="on" :left="true" :absolute="false">
                            <v-icon dark>far fa-eye</v-icon>
                        </v-btn>
                    </template>
                    <span>View on Virtual Assistant</span>
                </v-tooltip>
            </v-card-actions>
        </v-card>
    </v-col>
</template>
<script>
export default {
    name: "QStatProdByCarrier",
    props: {
        'carrier': {
            type: String,
            default: "Carrier Name"
        },
        'href': {
            type: String,
            default: '#'
        },
        'percentage': {
            type: Number,
            default: 15
        },

    },
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
    watch: {

    },
}
</script>
<style scoped>
</style>