<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <v-row class="mx-5">
            <v-col cols="7" sm="6">
                <h2>AgentConfig Bulk Upload</h2>
            </v-col>
            <v-col cols="12">
                <q-file-upload
                    upload-url="/api/private/admin/agentconfig_bulk_upload"
                    :accepted-files="accepted_files"
                    upload-name="document"
                    :max-files="null"
                    :text="upload_text"
                    @complete="uploadComplete"
                >
                </q-file-upload>
            </v-col>
        </v-row>
        <v-row justify="space-between" align="stretch" class="mx-5" v-if="showResp">
            <v-col cols="12">
				Errors:
				<li v-for="err in errors">
					{{ err }}
				</li>

			</v-col>
        </v-row>
    </v-container>
</template>
<script>
import QFileUpload from '@/components/utils/QFileUpload.vue'
import QExistingLeadOrderAuctionDataTable from '@/components/datatables/LeadOrders/QExistingLeadOrderAuctionDataTable.vue'
export default {
    name: "AgentConfigBulkUpload",
    props: [], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            showResp: false,
            upload_text: "Drag CSV file",
			errors: [],
        }
    },
    mounted: function() {

    },
    computed: {
        accepted_files() {
            return [
                'application/csv',
                '.csv',
            ].toString()
        },

    },
    methods: {
        uploadComplete(resp) {
			this.errors = resp.data.errors;
			this.showResp = this.errors.length > 0;
        }
    },
    watch: {

    },
    components: {
        QFileUpload,
        QExistingLeadOrderAuctionDataTable,
    },
}
</script>
<style scoped>
</style>