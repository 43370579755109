<template>
    <div>
        <v-card flat>
            <h3>Staff
                <v-btn icon @click="refreshData">
                    <v-icon>fas fa-redo-alt</v-icon>
                </v-btn>
            </h3>
            <v-text-field dense v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="id" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items">
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QGenericDataTableMixin from "./QGenericDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QStaffDataTable",
    props: [],
    mixins: [QGenericDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            //use this to "preset" a filter
            filters: {},
            options: {
                'sortBy': [],
                'sortDesc': [true]
            },
            expanded: [],
        }
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return [];
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers: function() {
            return [{
                    text: 'Name',
                    value: 'name',
                }, {
                    text: 'Email',
                    value: 'email',
                    align: "left"
                }, {
                    text: 'Role',
                    value: 'role',
                    align: "left"
                }, {
                    text: 'ID',
                    value: 'user_id',
                },

            ]
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            QuilityAPI.getStaff().then(function(json) {
                g.data_loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                g.$set(g, 'the_data', json.data);
                g.total_num = json.total
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
            this.firstPull = false;
        }, 200),
    },
    watch: {

    },
    components: {}
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
