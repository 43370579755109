<template>
    <QDynamicSubmenu
        :default-title="title"
        :default-buttons="buttons"
        :titlelink="titlelink"
        :submenu="submenu"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QStoredProcSubmenu",
    data() {
        return {
            title: "Stored Procedures",
            titlelink: [],
            buttons: [{
                    label: "Agent Upline Carrier",
                    to: "/admin/storedproc/AgentUplineCarrier",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Snapshot Agents",
                    to: "/admin/storedproc/SnapshotAgents",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Snapshot Agency Leader board",
                    to: "/admin/storedproc/SnapshotAgencyLeaderboard",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Snapshot Carrier",
                    to: "/admin/storedproc/SnapshotCarrier",
                    icon: "fas fa-arrow-alt-circle-right",
                }
            ],
            submenu: [],
        }
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>
