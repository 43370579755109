<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agency-building-submenu></q-agency-building-submenu>
        <!-- Charts-->
        <v-row justify="space-between" align="stretch" class="mx-5" id="OnboardingReport">
            <v-col cols="12">
                <h4>Weekly Onboarding Report</h4>
                <div v-if="startDate">
                    <span class="q-display-3">{{agent_count}} Agents Onboarded</span>
                    {{formatDateCalendar(startDate)}} to {{formatDateCalendar(endDate)}}
                </div>
                <q-weekly-onboarding-report-data-table ref="datatable" title="New Agents" v-on:loaded="setCount"></q-weekly-onboarding-report-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QAgencyBuildingSubmenu from '@/components/navigation/Submenus/QAgencyBuildingSubmenu.vue';
import QWeeklyOnboardingReportDataTable from '@/components/datatables/Reports/QWeeklyOnboardingReportDataTable.vue'

export default {
    name: "Agents",
    data() {
        return {
            agent_count: 0,
            startDate: null,
            endDate: null
        };
    },
    mounted: function() {},
    computed: {

    },
    methods: {
        setCount: function(info) {
            console.log(info)
            this.agent_count = info.total_items
            this.startDate = info.startDate
            this.endDate = info.endDate
        }
    },
    components: {
        QAgencyBuildingSubmenu,
        QWeeklyOnboardingReportDataTable
    }
};

</script>
