<template>
    <v-card>
        <v-card-title>
            <h5>Custom API Query</h5>
        </v-card-title>
        <v-card-text class="scroll-overflow">
            <v-text-field v-model="url" label="API Endpoint"></v-text-field>
            <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
            <pre>apiMessage : {{ apiMessage }}</pre>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="callApi()">Test Api</v-btn>
            <v-btn v-if="apiMessage" @click="forceFileDownload">Download</v-btn>
            <v-btn @click="clearCache">Clear Cache</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import axios from "axios";

export default {
    name: "QApiQuery",
    props: [],
    data() {
        return {
            apiMessage: null,
            loading: false,
            url: '/api/private/agents/'
        };
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {
        callApi() {
            var g = this;
            this.loading = true;
            g.apiMessage = null;
            // Get the access token from the auth wrapper
            this.$auth.getTokenSilently().then(
                function(token) {
                    axios.get(g.url, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    }).then(
                        function(data) {
                            g.loading = false
                            g.apiMessage = JSON.stringify(data.data, null, 2);
                        }
                    ).catch(function(e) {
                        g.showError("Whoops!")
                        g.apiMessage = JSON.stringify(e.response.data, null, 2);
                        g.loading = false;
                    });;
                }
            ).catch(function(e) {
                g.showError("Whoops! Token error")
                g.apiMessage = "TOKEN ERROR :: " + JSON.stringify(e, null, 2);
                g.loading = false;
            });
        },
        clearCache: function() {
            this.$set(this, 'url', '/api/private/cache/clear');
            this.$nextTick(this.callApi)
        },
        forceFileDownload() {
            const url = window.URL.createObjectURL(new Blob([this.apiMessage]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'data.json') //or any other extension
            document.body.appendChild(link)
            link.click()
        },
    }
};

</script>
<style scoped>
.scroll-overflow {
    max-height: 400px;
    overflow: scroll;
}

</style>
