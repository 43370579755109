<script>
import QStoredProcDataTableBase from "./QStoredProcDataTableBase"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QCarrierDataTable",
    mixins: [QStoredProcDataTableBase],
    props: ['procParams'],
    data: function() {
        return {
            is_loading: false
        }
    },
    computed: {
        the_title: function() {
            return 'Carrier';
        },
        loading: function() {
            return this.is_loading;
        },
        the_headers: function() {
            return [{
                    text: 'Carrier',
                    value: 'Carrier',
                },
                {
                    text: 'Submitted %',
                    value: 'CarrierSubmittedPercentage',
                },
                {
                    text: 'Submit APV',
                    value: 'Submitted',
                },
                {
                    text: 'Placed %',
                    value: 'CarrierNetPlacedPercentage',
                }, {
                    text: 'Net Placed',
                    value: 'NetPlaced',
                }, {
                    text: 'NPP',
                    value: 'NPP',
                }, {
                    text: 'Start Date',
                    value: 'PeriodStartDate',
                }, {
                    text: 'End Date',
                    value: 'PeriodEndDate',
                }, {
                    text: '',
                    value: '$id',
                }
            ]
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            var params = {
                startDate: this.procParams.startDate,
                endDate: this.procParams.endDate,
                apvCap: this.procParams.apvCap,
                leadLevels: this.procParams.leadLevels.join(';'),
                ...opt
            }
            this.is_loading = true;
            QuilityAPI.getRequest('/api/private/admin/storedproc/Report_Snapshot_Carrier', params, 'Snapshot').then(function(json) {
                g.is_loading = false;
                g.$set(g, 'items', json.data);
                g.items_length = json.meta.total
            }).catch(function(err) {
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.$set(g, 'items', []);
                g.is_loading = false;
                g.showError(err.response.body.message)
            });
        }, 200),
    },
    watch: {
        'procParams': function(newV) {
            this.$nextTick(this.refreshData);
        }
    },
    components: {}
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
