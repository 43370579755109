<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-admin-audit-submenu></q-admin-audit-submenu>
        <!-- Charts-->
        <v-row justify="space-between" align="stretch" class="mx-5" id="OnboardingReport">
            <v-col cols="12">
                <q-admin-logs></q-admin-logs>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QAdminAuditSubmenu from '@/components/navigation/Submenus/QAdminAuditSubmenu.vue';
import QAuditDataTable from '@/components/datatables/Audit/QAuditDataTable.vue'
import QAdminLogs from '@/components/utils/QAdminLogs.vue'

export default {
    name: "Agents",
    data() {
        return {};
    },
    mounted: function() {},
    computed: {

    },
    methods: {},
    watch: {

    },
    components: {
        QAdminAuditSubmenu,
        QAuditDataTable,
        QAdminLogs
    }
};

</script>
