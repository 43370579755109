<template>
    <div class="about">
        <h1>API & Auth0 Test</h1>
        <q-api-query>
        </q-api-query>
        <div>
            <img :src="$auth.auth_user.picture">
            <h2>{{ $auth.auth_user.name }}</h2>
            <p>Token : {{ token }}</p>
            <p>VirtualMentor Token : {{ VM_token }}
                <p>{{ $auth.auth_user.email }}</p>
                <p>Role: {{ role }}</p>
                <p>Roles: {{ roles }}</p>
                <p>Permissions: {{ user.permissions }}</p>
                <p>Has manage:AgentLevel permission? : {{ hasPermission("manage:AgentLevel") ? "Yes" : "No" }}</p>
                <p>Has manage:AgentHierarchies permission? : {{ hasPermission("manage:AgentHierarchies") ? "Yes" : "No" }}</p>
                <p>Is Super Admin? {{ hasRole("SuperAdmin") ? "Yes" : "No" }}</p>
                <p>Is SalesRep or AgencyOwner? {{ hasRole(["SalesRep", "AgencyOwner"]) ? "Yes" : "No" }}</p>
                <p>Is Not a AgencyOwner? {{ notRole("AgencyOwner") ? "True" : "False" }}</p>
        </div>
        <div>
            <h3>Frontend (SPA) Token</h3>
            <h4>User Token</h4>
            <pre>{{ JSON.stringify($auth.auth_user, null, 2) }}</pre>
        </div>
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                </v-col>
                <v-col cols="4">
                    <q-api-test url="/api/public" title="Public End Point"></q-api-test>
                </v-col>
                <v-col cols="4">
                    <q-api-test url="/api/private/test" title="Private End Point"></q-api-test>
                </v-col>
                <v-col cols="4">
                    <q-api-test url="/api/private/messages" title="Private End Point - Agency Owner Only"></q-api-test>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import QApiTest from '@/components/utils/QApiTest.vue';
import QApiQuery from '@/components/utils/QApiQuery.vue';
import AuthLinkAccounts from '@/auth/LinkAccounts.vue';
import auth0 from 'auth0-js';

export default {
    name: "APITest",
    data() {
        return {
            apiMessage: "",
            loading: false,
            token: null,
            VM_token: null
        };
    },
    mounted: function() {
        var g = this
        this.$auth.getTokenSilently().then(function(token) {
            g.token = token;
        })

        var webAuth = new auth0.WebAuth({
            domain: 'auth-dev.quility.com',
            clientID: process.env.VUE_APP_AUTH0_CLIENT_ID
        });
        /*
        webAuth.authorize({
            audience: 'https://api.virtualmentor.us/',
            scope: 'openid email profile',
            redirect_uri: process.env.VUE_APP_BASE_URL,
            responseType: 'id_token token code',
        }, function() {});
        */
        var opts = {
            domain: 'auth-dev.quility.com',
            audience: 'https://api.virtualmentor.us/',
            scope: 'openid email profile',
            redirect_uri: process.env.VUE_APP_BASE_URL + '/admin/linkaccounts'
        };

        this.$auth.getTokenSilently(opts).then(function(token) {
            g.VM_token = token;
        })

    },
    computed: {

    },
    methods: {

    },
    components: {
        QApiTest,
        QApiQuery,
        AuthLinkAccounts
    }
};

</script>
